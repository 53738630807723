import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { ChatService } from 'src/app/services/chat.service';
import { FormControl } from '@angular/forms';
import { isNil } from 'lodash';
import { debounce } from 'src/app/directives/debounce.decorator';
import { StreamsService } from 'src/app/services/streams.service';
import { ConfigService } from 'src/app/services/config.service';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { SocketService } from 'src/app/services/socket.service';
import { ChatScreenComponent } from '../../chat-screen/chat-screen.component';
import { IdentityService } from 'src/app/services/identity.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DateRangePickerComponent, DateRangePreset } from '../../date-range-picker/date-range-picker.component';
import { StratsService } from 'src/app/services/strats';
import { PromptsService } from 'src/app/services/prompts.service';
import { FileService } from 'src/app/services/file.service';
import { WidgetService } from 'src/app/services/widget.service';

const MS_PER_DAY = 1000 * 60 * 60 * 24;

interface TickerData {
  symbol: string;
  description: string;
}

const cutMillisFromTimestamp = (value: Date | number) => Math.trunc((value instanceof Date ? value.getTime() : value) / 1000);

@Component({
  selector: 'app-agent-widget',
  templateUrl: './agent-widget.component.html',
  styleUrls: ['../base-widget/base-widget.component.scss', './agent-widget.component.scss']
})
export class AgentWidgetComponent extends BaseWidgetComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('tickerInput') tickerInput: ElementRef;
  @ViewChild('dateRangePicker') dateRangePicker!: DateRangePickerComponent;
  @ViewChild('componentChat', { static: true }) chatScreen: ChatScreenComponent;
  @ViewChild('chatInput') chatInput!: ElementRef<HTMLInputElement>;

  @Output() onStrategyEditor = new EventEmitter<{ parentId: string; modelName: string; name: string }>();
  @Output() onEditFile = new EventEmitter<{ path: any }>();
  @Output() modelChange = new EventEmitter<string>();

  @Input() model: string | undefined;
  @Input() ticker: string | undefined;
  @Input() interval: string | undefined;
  @Input() presetDateRange: DateRangePreset | undefined;
  @Input() dateRange: [Date, Date] | undefined;

  private _calls: number = 0;
  private _returns: number = 0;
  private _dataFeedUrl: string;
  private _chatIndex: number = null;

  public availableModels: ChatService.AvailableModel[] = [];
  public strategies: any[] = [];
  public selectedStrategy: any = null;
  public selectedStrategyPath: string = null;
  public selectedPrompts: string[] = [];
  public editingPrompts = false;
  public prompts: any[] = [];
  public isLoading: boolean = false;
  public messages: ChatService.ChatMessage[] = [];
  public tickers: TickerData[] = [];
  public inputTicker = new FormControl('');
  public input: string = null;

  public get promptNames(): string[] {
    return this.prompts.map((x) => x.name);
  }

  public get submitDisabled() {
    return !this.input || this.input.trim().length === 0 || this.isLoading;
  }

  public get selfTurns() {
    return this.messages.filter(({ isSelf }) => isSelf);
  }

  public get llmName(): string {
    return `${this.ticker.toLocaleUpperCase()}.llm`;
  }

  constructor(
    private _chatService: ChatService,
    private _identity: IdentityService,
    private _streamsService: StreamsService,
    private _socketService: SocketService,
    private stratsService: StratsService,
    private _promptService: PromptsService,
    private _fileService: FileService,
    private _widgetService: WidgetService
  ) {
    super();
  }

  public ngOnInit() {
    if ((<any>window).agent) (<any>window).agent.push(this);
    else (<any>window).agent = [this];

    this.model ??= 'deepseek-r1:70b';
    this.ticker ??= 'DAL';
    this.interval ??= '1day';
    this.presetDateRange ??= '6month';

    this._dataFeedUrl = `${ConfigService.udfUrl}/${this.teamName}`;

    const sub = this._streamsService.load(this.teamName).subscribe((response: any) => {
      sub.unsubscribe();
      this.updateTickerAutocomplete(this.ticker);
    });

    const stratSub = this.stratsService.load(this.teamName).subscribe((response: any) => {
      this.strategies = response as any[]; // Assign loaded strategies
      this.loadStrategy();
      stratSub.unsubscribe();
    });

    const promptSub = this._promptService.load(this.teamName).subscribe((response: any) => {
      const excludeThese = ['System Prompt.txt', 'Final Prompt.txt'];
      this.prompts = (response as any[]).filter((x) => !excludeThese.includes(x.name) && x.type == "text"); // Assign loaded strategies
      promptSub.unsubscribe();
    });

    this.inputTicker.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe(async (value) => {
      this.updateTickerAutocomplete(value);
    });

    this._socketService.joinRoom('llm');
    this._subs.add = this._socketService.subscribeToRoomEvents('llm', (data: any) => {
      if (data.origin === 'udf' && data.definition == this.llmName) {
        this.isLoading = false;
        this.processData(data);
      }
    });

    this._chatService.modelsAsync().then(({ revisors, hasAllModels }) => {
      this.availableModels = revisors;
    })
    .catch(console.warn);

  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  private processData(data) {
    if(!data || !data.input || !data.msg) {
      return;
    }

    this.messages = [{ isSelf: true, content: data.final_prompt }];
    for (var i = 0; i < data.input?.prompts.length; i++) {
      this.messages.push(
        { title: 'System Prompt', isCollapsed: true, isSelf: true, content: data.system_prompt, lineBreak: true },
        { title: data.input.names[i], isSelf: true, content: data.input.prompts[i] },
        { isSelf: false, content: data.input.responses[i] }
      );
    }
    this.messages.push({ isSelf: false, content: data.msg, lineBreak: true });

    if(data.last_modified_time) {
      this.messages.push({ isSelf: false, content: `Analysis generated at ${data.last_modified_time}. Run /go to generate a new analysis.`, lineBreak: true });
    }

    this._scrollToBottom();
  }

  private _saveInputs(): void {
    this._widgetService.patchWidget(this.teamName, this.id, {
      inputs: {
        id: this.id,
        interval: this.interval,
        isMaximized: this.isMaximized,
        model: this.model,
        presetDateRange: this.presetDateRange,
        dateRange: this.dateRange,
        teamName: this.teamName,
        ticker: this.ticker
      }
    });
  }

  async toggleAdvanced() {
    this.editingPrompts = !this.editingPrompts;
  }

  async loadStrategy() {
    const name = this.ticker.toLocaleUpperCase() + '.llm';
    const strategy = this.strategies.find((x) => x.name == name);
    if(strategy) {
      this._fileService.get(this.teamName, strategy.path).then((data) => {
        console.debug(`Loaded ${name}`)
        this.selectedStrategy = JSON.parse(data);
        this.selectedStrategyPath = strategy.path;
        this.selectedPrompts = this.selectedStrategy['prompts'] || [];
      });
    }
    else{
      this.selectedStrategy = {
        "model": "llama3.2:latest",
        "prompts": [ ]
      }
      this.selectedStrategyPath = `/data/${this.teamName}/strategies/${name}`;
      await this.saveStrategy();
      console.info(`Created new strategy ${this.ticker}.llm`)
    }
  }

  onTickerChange() {
    this._saveInputs();
    this.loadStrategy();
    this.loadCachedData();
  }

  onPromptClick(event: string) {
    // console.log("Prompt clicked:", event);
    const path = `/data/${this.teamName}/prompts/${event}`;
    console.log(path);
    this.onEditFile.emit({ path });
    // Handle chip click if needed
  }

  onPromptAdded(event: { name: string }) {
    // console.log("Prompt added:", event.name);

    // Always update the selectedStrategy and save
    if (this.selectedStrategy) {
      this.selectedStrategy.prompts = this.selectedPrompts;
      // console.log("Updated selectedStrategy.prompts:", this.selectedStrategy.prompts);

      // Save the strategy
      this.saveStrategy();
    } else {
      console.warn('No selected strategy to update.');
    }
  }

  onPromptRemoved(event: { name: string }) {
    // console.log("Prompt removed:", event.name);
    this.selectedPrompts = this.selectedPrompts.filter((name) => name !== event.name);
    // console.log("Updated selectedPrompts:", this.selectedPrompts);

    if (this.selectedStrategy) {
      this.selectedStrategy.prompts = this.selectedPrompts;
      // console.log("Updated selectedStrategy.prompts:", this.selectedStrategy.prompts);

      // Save the strategy
      this.saveStrategy();
    } else {
      console.warn('No selected strategy to update.');
    }
  }

  onPromptReordered(event: { previousIndex: number; currentIndex: number }) {
    // console.log(`Prompt reordered from ${event.previousIndex} to ${event.currentIndex}`);

    // Re-save the strategy after reordering prompts
    if (this.selectedStrategy) {
      this.selectedStrategy.prompts = [...this.selectedPrompts]; // Update with the reordered prompts
      this.saveStrategy();
    }
  }

  private async saveStrategy() {
    // console.log("saveStrategy called");
    if (!this.selectedStrategy) {
      console.error('No strategy selected to save.');
      return;
    }

    const path = this.selectedStrategyPath;
    try {
      await this._fileService.postAsync(this.teamName, path, 'json', this.selectedStrategy);
      console.log(`Strategy saved successfully: ${path}`);
    } catch (error) {
      console.error(`Failed to save strategy at ${path}`, error);
    }
  }

  @debounce(100)
  private _scrollToBottom(): void {
    this.chatScreen.scrollToBottom();
  }

  @debounce(500)
  public loadCachedData(): void {
    this.isLoading = true;
    const dataFeedUrl = this._dataFeedUrl;
    this._calls++;

    var sub2 = this._streamsService
      .getCachedAnalysis(dataFeedUrl, this.ticker)
      .subscribe((response) => {
        this.isLoading = false;
        this.processData(response);
        sub2.unsubscribe();
        this._returns++;
      });
  }

  @debounce(500)
  public loadModelData(from: number, to: number): void {
    this.isLoading = true;
    const dataFeedUrl = this._dataFeedUrl;
    this._calls++;

    const modelNames = [this.ticker.toLocaleUpperCase() + ':top200_608'];
    var sub2 = this._streamsService
      .getModelData(dataFeedUrl, modelNames.join(','), this.interval, from, to, 'mean', [this.llmName])
      .subscribe(() => {
        this.isLoading = false;
        sub2.unsubscribe();
        this._returns++;
      });
  }

  public async updateTickerAutocomplete(value: string): Promise<void> {
    const symbols: any[] = <any>await this._streamsService.symbolSearch(this._dataFeedUrl, value, 20);
    if (symbols && symbols.length > 0) {
      this.tickers = symbols satisfies TickerData[];
      if (symbols.length == 1) this.ticker = this.tickers[0].symbol;
    }
  }

  public onTickerInputClick(): void {
    this.tickerInput.nativeElement.focus();
  }

  public presetDateRangeToDateRange(preset: DateRangePreset): [Date, Date] | undefined {
    if (preset === 'custom') return undefined;

    const backwardDays = preset === '1month' ? 31 : preset === '3month' ? 90 : preset === '6month' ? 180 : 7;

    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0).getTime();
    const start = today - MS_PER_DAY * backwardDays;

    return [new Date(start), new Date(today)];
  }

  @debounce(100)
  private _focusPromptBox(): void {
    setTimeout(this.chatInput.nativeElement.focus.bind(this.chatInput.nativeElement), 0);
  }

  public $changeDateRange(): void {
    this._saveInputs();
    const endPushedForward = this.dateRange[1].getTime() + MS_PER_DAY;
    this.loadModelData(cutMillisFromTimestamp(new Date(this.dateRange[0])), cutMillisFromTimestamp(new Date(this.dateRange[1]).getTime() + MS_PER_DAY));
  }

  public $clear(): void {
    this._chatService
      .clearAsync(this.teamName, this._identity.me.username)
      .then((_response) => {
        this.isLoading = false;
        this.messages.splice(0, this.messages.length);
      })
      .catch((err) => {
        this.isLoading = false;
        console.error(err);
      });
  }

  public $pressUp($event: KeyboardEvent): void {
    $event.preventDefault();
    $event.stopImmediatePropagation();

    if (this.selfTurns.length === 0) return;
    if (this._chatIndex === 0) return;
    this._chatIndex = (this._chatIndex ?? this.selfTurns.length) - 1;
    this.input = this.selfTurns[this._chatIndex].content;
  }

  public $pressDown($event: KeyboardEvent): void {
    $event.preventDefault();
    $event.stopImmediatePropagation();

    if (this.selfTurns.length === 0) return;
    if (this._chatIndex === this.selfTurns.length - 1) return this.$pressEscape($event);
    if (this._chatIndex === null) return;
    this._chatIndex++;
    this.input = this.selfTurns[this._chatIndex].content;
  }

  public $pressEscape($event: KeyboardEvent): void {
    $event.preventDefault();
    $event.stopImmediatePropagation();

    if (this._chatIndex !== null) this._chatIndex = null;
    if (this.input) this.input = '';
  }

  public async $submit(prompt?: string): Promise<void> {
    if (this.submitDisabled) return;

    this.isLoading = true;

    const content = prompt ?? this.input;

    if (content !== '/clear') this.messages.push({ isSelf: true, content });
    this.input = '';
    this._chatIndex = null;
    this._scrollToBottom();

    if (content === '/go') {
      this.$clear();
      if (isNil(this.dateRange) || isNil(this.dateRange[0]) || isNil(this.dateRange[1])) {
        // If the user has not yet selected a preset date or custom range, manually patch it in
        this.dateRange = this.dateRangePicker.range;
      }
      this.loadModelData(cutMillisFromTimestamp(new Date(this.dateRange[0])), cutMillisFromTimestamp(new Date(this.dateRange[1]).getTime() + MS_PER_DAY));
    } else if (content === '/clear') {
      this.$clear();
    } else {
      this._chatService
        .chatAsync(this.teamName, this._identity.me.username, content, this._identity.me.selectedProjectName, this.model)
        .then((response) => {
          this.isLoading = false;
          this.messages.push({ isSelf: false, content: response.error ?? response.output, isError: !isNil(response.error) });
        })
        .catch((err) => {
          this.isLoading = false;
          console.error(err);

          if (err instanceof HttpErrorResponse) {
            this.messages.push({ isSelf: false, content: 'Internal Server Error', isError: true });
          }
        })
        .finally(() => {
          this._scrollToBottom();
          this._focusPromptBox();
        });
    }
  }

  public $changeModel(model: string): void {
    console.log(model);
    this.modelChange.emit(model);
    this.model = model;
    this.model = this.availableModels.find((availableModel) => availableModel.model === model)?.model;
  }

}
