import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { ChatService } from 'src/app/services/chat.service';
import { IdentityService } from 'src/app/services/identity.service';
import { ConfigService } from 'src/app/services/config.service';
import { isNil } from 'lodash';
import { HttpErrorResponse } from '@angular/common/http';
import { debounce } from 'src/app/directives/debounce.decorator';
import { SocketService } from 'src/app/services/socket.service';
import { ChatScreenComponent } from '../../chat-screen/chat-screen.component';
import { WidgetService } from 'src/app/services/widget.service';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-chat-widget',
  templateUrl: './chat-widget.component.html',
  styleUrls: ['../base-widget/base-widget.component.scss', './chat-widget.component.scss'],
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'model-selector-overlay-pane' }
    }
  ]
})
export class ChatWidgetComponent extends BaseWidgetComponent implements OnInit, OnDestroy {
  @ViewChild('chat', { static: true }) chatScreen: ChatScreenComponent;
  @ViewChild('chatInput') chatInput!: ElementRef<HTMLInputElement>;

  @Input() messages: ChatService.ChatMessage[] = [];
  @Input() disabled: boolean = false;
  @Input() model: string = 'llama3.1:latest';

  @Output() onViewExperiments = new EventEmitter<{ projectName: string }>();
  @Output() onViewDefinition = new EventEmitter<{ projectName: string; definitionName: string }>();
  @Output() onViewBuild = new EventEmitter<{ projectName: string; runid: number }>();
  @Output() onViewAsset = new EventEmitter<{ projectName: string; assetName: string }>();
  @Output() onViewFeatureExplorer = new EventEmitter<{ projectName: string }>();
  @Output() onViewVault = new EventEmitter<{ projectName: string }>();
  @Output() onViewUpload = new EventEmitter();
  @Output() modelChange = new EventEmitter<string>();

  public input: string = null;
  public loading = false;
  public availableModels: ChatService.AvailableModel[] = [];
  public modelName: string = 'Llama3.1';
  public pullStatus: string = 'Making sure you have the latest models';
  public pullProgress: number = 0;
  public pullProgressBuffer: number = 0;
  public hasAllModels: boolean = false;

  private _chatIndex: number = null;

  public get submitDisabled() {
    return !this.input || this.input.trim().length === 0 || this.loading || !this.hasAllModels;
  }

  public get selfTurns() {
    return this.messages.filter(({ isSelf }) => isSelf);
  }

  public get isDesktopEnvironment(): boolean {
    return this._settings.desktopEnvironment;
  }

  constructor(
    private readonly _chat: ChatService,
    private readonly _identity: IdentityService,
    private readonly _socket: SocketService,
    private readonly _widget: WidgetService,
    private readonly _settings: SettingsService
  ) {
    super();
  }

  public ngOnInit() {
    if ((<any>window).chat) (<any>window).chat.push(this);
    else (<any>window).chat = [this];

    this._socket.joinRoom('chat');
    this._subs.add = this._socket.subscribeToRoomEvents('chat', (data: any) => {
      if (data.origin === 'experiment.change') {
        this.messages.push({ isSelf: false, content: data.msg });
      } else if (data.origin === 'pull.update') {
        const { progress } = data;
        this.pullProgress = progress.total * 100;
        this.pullProgressBuffer = progress.buffer * 100;
      } else if (data.origin === 'pull.complete') {
        const { failed } = data;

        if (failed.length !== 0) {
          this.pullStatus = 'Failed to pull one or more models';
          console.warn('failed to pull', failed);
        } else {
          this.hasAllModels = true;
        }
      }
    });

    this._chat
      .modelsAsync()
      .then(({ revisors, hasAllModels }) => {
        this.availableModels = revisors;
        this.hasAllModels = hasAllModels;
      })
      .catch(console.warn);

    this.getHistory();
  }

  public ngOnDestroy(): void {
    this._socket.leaveRoom('chat');
    super.ngOnDestroy();
  }

  @debounce(100)
  private _scrollToBottom(): void {
    this.chatScreen.scrollToBottom();
  }

  @debounce(100)
  private _focusPromptBox(): void {
    setTimeout(this.chatInput.nativeElement.focus.bind(this.chatInput.nativeElement), 0);
  }

  public async getHistory(): Promise<void> {
    if (!ConfigService.chatUrl) {
      console.error('ChatUrl not found.');
      return;
    }

    const sub = this._chat.history(this.teamName, this._identity.me.username).subscribe((response: any) => {
      sub.unsubscribe();
      this.messages.splice(0, this.messages.length);
      this.messages.push(...response);
      this._scrollToBottom();
    });
  }

  public async $submit(prompt?: string): Promise<void> {
    if (this.submitDisabled) return;

    this.loading = true;

    const content = prompt ?? this.input;

    if (content !== '/clear') this.messages.push({ isSelf: true, content });
    this.input = '';
    this._chatIndex = null;
    this._scrollToBottom();

    if (content === '/clear') {
      this.$clear();
    } else {
      this._chat
        .chatAsync(this.teamName, this._identity.me.username, content, this._identity.me.selectedProjectName, this.model)
        .then((response) => {
          this.loading = false;
          this.messages.push({ isSelf: false, content: response.error ?? response.output, isError: !isNil(response.error) });
        })
        .catch((err) => {
          this.loading = false;
          console.error(err);

          if (err instanceof HttpErrorResponse) {
            this.messages.push({ isSelf: false, content: 'Internal Server Error', isError: true });
          }
        })
        .finally(() => {
          this._scrollToBottom();
          this._focusPromptBox();
        });
    }
  }

  public $clear(): void {
    this._chat
      .clearAsync(this.teamName, this._identity.me.username)
      .then((_response) => {
        this.loading = false;
        this.messages.splice(0, this.messages.length);
        this._widget.save(this.teamName);
      })
      .catch((err) => {
        this.loading = false;
        console.error(err);
      });
  }

  public $pressUp($event: KeyboardEvent): void {
    $event.preventDefault();
    $event.stopImmediatePropagation();

    if (this.selfTurns.length === 0) return;
    if (this._chatIndex === 0) return;
    this._chatIndex = (this._chatIndex ?? this.selfTurns.length) - 1;
    this.input = this.selfTurns[this._chatIndex].content;
  }

  public $pressDown($event: KeyboardEvent): void {
    $event.preventDefault();
    $event.stopImmediatePropagation();

    if (this.selfTurns.length === 0) return;
    if (this._chatIndex === this.selfTurns.length - 1) return this.$pressEscape($event);
    if (this._chatIndex === null) return;
    this._chatIndex++;
    this.input = this.selfTurns[this._chatIndex].content;
  }

  public $pressEscape($event: KeyboardEvent): void {
    $event.preventDefault();
    $event.stopImmediatePropagation();

    if (this._chatIndex !== null) this._chatIndex = null;
    if (this.input) this.input = '';
  }

  public $changeModel(model: string): void {
    this.modelChange.emit(model);
    this.model = model;
    this.modelName = this.availableModels.find((availableModel) => availableModel.model === model)?.name ?? 'Llama3.1';
  }
}
