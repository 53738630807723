import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { StreamsService } from 'src/app/services/streams.service';
import { FormControl } from '@angular/forms';
import { SettingsService } from 'src/app/services/settings.service';
import {
  SciChartSurface,
  NumericAxis,
  FastCandlestickRenderableSeries,
  OhlcDataSeries,
  NumberRange,
  ZoomExtentsModifier,
  ZoomPanModifier,
  MouseWheelZoomModifier,
  CursorModifier,
  SeriesInfo,
  CursorTooltipSvgAnnotation,
  EDataSeriesType,
  OhlcSeriesInfo,
  IFillPaletteProvider,
  EFillPaletteMode,
  parseColorToUIntArgb,
  IRenderableSeries,
  IPointMetadata,
  FastColumnRenderableSeries,
  XyDataSeries,
  EAutoRange,
  DataPointSelectionModifier,
  SciChartVerticalGroup,
  RolloverModifier,
  ESelectionMode,
  TModifierKeys,
  DateTimeNumericAxis,
  TSciChart,
  CustomAnnotation,
  EVerticalAnchorPoint,
  EHorizontalAnchorPoint,
  XAxisDragModifier,
  YAxisDragModifier,
  FastLineRenderableSeries,
  ELineDrawMode,
  FastOhlcRenderableSeries,
  EZoomState
} from 'scichart';
import { appTheme } from '../../../modules/shared/material-theme';
import { ConfigService } from 'src/app/services/config.service';
import { ModelIndicatorsService } from 'src/app/services/model-indicators-service';
import { WidgetService } from 'src/app/services/widget.service';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { debounce } from 'src/app/directives/debounce.decorator';
import { IndicatorsService } from 'src/app/services/indicators.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { SocketService } from 'src/app/services/socket.service';
import { SubscriptSizing } from '@angular/material/form-field';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

const Y_AXIS_VOLUME_ID = "Y_AXIS_VOLUME_ID";

function debug(...args) {
  // console.log(...args);
}

function formatDate(date) {
  let retval = null;
  try {
    retval = new Date(date*1000).toISOString();
  }
  catch(exc) {}
  return retval;
}

export class VolumePaletteProvider implements IFillPaletteProvider {
  fillPaletteMode: EFillPaletteMode = EFillPaletteMode.SOLID;
  private ohlcDataSeries: OhlcDataSeries;
  private upColorArgb: number;
  private downColorArgb: number;

  constructor(masterData: OhlcDataSeries, upColor: string, downColor: string) {
    this.upColorArgb = parseColorToUIntArgb(upColor);
    this.downColorArgb = parseColorToUIntArgb(downColor);
    this.ohlcDataSeries = masterData;
  }
  onAttached(parentSeries: IRenderableSeries): void {}
  onDetached(): void {}

  // Return up or down color for the volume bars depending on Ohlc data
  overrideFillArgb(xValue: number, yValue: number, index: number, opacity?: number, metadata?: IPointMetadata): number {
    const isUpCandle = this.ohlcDataSeries.getNativeOpenValues().get(index) < this.ohlcDataSeries.getNativeCloseValues().get(index);
    return isUpCandle ? this.upColorArgb : this.downColorArgb;
  }
}

@Component({
  selector: 'app-stream-widget',
  templateUrl: './stream-widget.component.html',
  styleUrls: ['../base-widget/base-widget.component.scss', './stream-widget.component.scss']
})
export class StreamWidgetComponent extends BaseWidgetComponent implements OnInit, OnDestroy {
  @ViewChild('tickerInput') tickerInput: ElementRef;

  @Input() streamName: string = null;
  @Input() interval: string = "1minute";
  @Input() activeIndicators = [];
  @Input() modelName = null;

  @Output() onModelSelect = new EventEmitter<{
    modelName: string;
    streamName: string;
    parentId: string;
    ticker: string;
    interval: string;
    parentChartData: any[];
  }>();
  @Output() onTickSelect = new EventEmitter<{ parentId: string; streamName: string; ticker: string; date: Date }>();
  @Output() onStrategy = new EventEmitter<{ parentId: string; modelName: string; ticker: string; strats: string[] }>();
  @Output() onCloseChildren = new EventEmitter<string[]>();
  @Output() onIndicatorSelect = new EventEmitter<{ parentId: string; activeIndicators: any[] }>();

  private graphIncrement: number = 20000;
  private _ohlcDataSeries: OhlcDataSeries;
  private _volumeSeries: XyDataSeries;

  public streams = [];
  // public tickers: any[] = [{ symbol: 'SPY', description: 'SPDR S&P 500' }];
  // public ticker = 'SPY';
  public tickers: any[] = [];
  public ticker: string = null;

  public dataFeedUrl: string;

  public isLoading = false;
  public inputTicker = new FormControl('');
  public timezone: string;
  public updateFrequency: number = 10;

  public title?: string | string[];
  public xValues: number[] = [];
  public openValues: number[] = [];
  public highValues: number[] = [];
  public lowValues: number[] = [];
  public closeValues: number[] = [];
  public volumeValues: number[] = [];

  public chart: SciChartSurface | undefined = undefined;

  private wasmContext: TSciChart | undefined;
  private sciChartSurface: SciChartSurface | undefined;
  private strats = [];

  public modifiers = {
    zoomPan: new ZoomPanModifier(),
    xAxisDrag: new XAxisDragModifier(),
    zoomExtents: new ZoomExtentsModifier(),
    mouseWheelZoom: new MouseWheelZoomModifier(),
    cursor: new CursorModifier({
      crosshairStroke: '#eee',
      axisLabelFill: appTheme.DarkIndigo
    }),
    dataPoint: new DataPointSelectionModifier(),
    rolloverModifier: new RolloverModifier({ showTooltip: false, modifierGroup: 'group1' }),
    yAxisDrag: new YAxisDragModifier()
  };

  public verticalGroup;

  public mainChartXAxes = null;
  public mainChartYAxes = null;
  public modifierKeys: TModifierKeys;

  public packetContent: any = null;
  public showPacket: boolean = false;
  public showMinuteBar = false;
  public lastMinuteBarDate = null;
  public models = [];
  public indicators = [];
  public selectedIndicatorName: string = null;
  private annotationMap = {};
  private children: string[] = [];
  private _socketSub: any;
  private _intervalId: number = null;
  public filter = null;
  applyFilter(value) {
    this.filter = value;
  }
  public get filteredModels(): string[] {
    if (!this.filter) return this.models;
    const retval = this.models.filter((x) => x.toLowerCase().indexOf(this.filter.toLowerCase()) !== -1);
    if (retval.length == 0) return this.models;
    return retval;
  }

  public tickerUpdate = new Subject<string>();
  public intervalUpdate = new Subject<string>();

  constructor(
    private _streamsService: StreamsService,
    private _settingsService: SettingsService,
    private _modelIndicatorsService: ModelIndicatorsService,
    private _strategyWidgetServce: WidgetService,
    private _indicatorsService: IndicatorsService,
    private _socketService: SocketService,
    private _snackbar: MatSnackBar
  ) {
    super();
  }

  ngOnInit(): void {
    if ((<any>window).streamcharts) {
      (<any>window).streamcharts.push(this);
    } else {
      (<any>window).streamcharts = [this];
    }

    // if(this.streamName) {
    //   this.onStreamSelect(this.streamName);
    // }

    this.dataFeedUrl = `${ConfigService.udfUrl}/${this.teamName}`;

    this.modifiers.rolloverModifier.modifierGroup = this.id;

    this._subs.add = this._strategyWidgetServce.modelsAdded$.subscribe((response) => {
      const newModels = response.models;
      for (let newModel of newModels) {
        if (!this.models.includes(newModel)) {
          this.models.push(newModel);
        }
      }
    });

    this._subs.add = this._strategyWidgetServce.stratsUpdate$.subscribe((response) => {
      if (response.parentId !== this.id) return;
      this.strats = response.strats;
      this.loadData();
    });

    this._subs.add = this._strategyWidgetServce.stratEdit$.subscribe((response) => {
      if (response.parentId !== this.id) return;
      this.loadData();
    });

    this._subs.add = this._strategyWidgetServce.childAdd$.subscribe((response) => {
      if (response.parentId !== this.id) return;

      const exists = this.children.find((x) => x === response.id);
      if (exists) return;

      this.children.push(response.id);
    });

    this._subs.add = this._strategyWidgetServce.childDelete$.subscribe((response) => {
      if (response.parentId !== this.id) return;

      this.children = this.children.filter((x) => x !== response.id);
    });

    this._subs.add = this._strategyWidgetServce.annotationsUpdate$.subscribe((response) => {
      if (response.parentId !== this.id) return;

      this.annotationMap[response.myId] = response.annotationMap;
      this.loadData();
      this.addAnnotations();
    });

    const sub = this._streamsService.load(this.teamName).subscribe((response: any) => {
      sub.unsubscribe();
      this.streams = response;
      // if (!this.streamName && this.streams.length > 0) {
      //   this.onStreamSelect(this.streams[0].name);
      // }

      this.init();
    });

    const modelsSub = this._modelIndicatorsService.load(this.teamName).subscribe(async (response) => {
      modelsSub.unsubscribe();
      this.models = response?.sort();
    });

    const indicatorsSub = this._indicatorsService.load(this.teamName).subscribe(async (response) => {
      indicatorsSub.unsubscribe();
      this.indicators = response?.filter((x) => x.type).sort();
    });

    this.inputTicker.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe(async (value) => {
      if (this.dataFeedUrl && value?.length > 1) {
        this._strategyWidgetServce.tickerUpdate$.next({ parentId: this.id, ticker: value });
        this.updateTickerAutocomplete(value);
        this.loadData();
      }
    });
  }

  ngOnDestroy(): void {
    if (this._intervalId) {
      clearInterval(this._intervalId);
    }

    if (this._socketSub) {
      this._socketSub.unsubscribe();
    }

    super.ngOnDestroy();
  }

  onTickerInputClick() {
    this.tickerInput.nativeElement.focus();
  }
  public async load() {
    const retval: any = await this._streamsService.getTimezoneAsync(this.teamName);
    if (retval) {
      this.timezone = retval.timezone;
    }

    const sub = this._streamsService.get(this.teamName, this.streamName).subscribe(async (response: any) => {
      sub.unsubscribe();

      if (response.hasOwnProperty('parameters')) {
        const keys = Object.keys(response.parameters);
        if (keys.includes('ticker')) {
          this.ticker = response.parameters.ticker;
        } else if (keys.includes('tickers')) {
          if (Array.isArray(response.parameters.tikers)) {
            this.ticker = response.parameters.tickers[0];
          } else if (response.parameters.tickers.includes(',')) {
            this.ticker = response.parameters.tickers.split(',')[0];
          } else {
            this.ticker = response.parameters.tickers;
          }
        }

        if (keys.includes('update_frequency_secs')) {
          this.updateFrequency = response.parameters.update_frequency_secs;
        }
        if (keys.includes('graph_increment')) {
          this.graphIncrement = response.parameters.graph_increment;
        }

      }

      setTimeout(() => {
        this.initTraffic();
      }, 1000);
    });
  }

  chartData = [];
  public loadData() {
    let now = new Date();
    now.setMinutes(now.getMinutes() + 2);
    const to = Math.floor(now.getTime() / 1000);
    const from = to - this.graphIncrement;

    this.loadChartData(from, to);
  }

  reset() {
    this.chartData = [];
    // this.modelData = [];
    this.xValues = [];
    this.openValues = [];
    this.highValues = [];
    this.lowValues = [];
    this.closeValues = [];
    this.volumeValues = [];

    this._strategyWidgetServce.parentChartDataUpdate$.next({ parentId: this.id, chartData: this.chartData });
  }

  public onStreamSelect(streamName) {
    this.streamName = streamName;
    this.isLoading = true;

    this.reset();

    // this.dataFeedUrl = `${ConfigService.udfUrl}/${this.teamName}/${this.streamName}`;
    this._strategyWidgetServce.streamUpdate$.next({ parentId: this.id, streamName: this.streamName });
    this.load();
    if (this.modelName) {
      this.modelSelect(this.modelName)
    }
  }

  public onIntervalSelect(interval) {
    this.interval = interval;
    this.isLoading = true;

    this.reset();

    // this.dataFeedUrl = `${ConfigService.udfUrl}/${this.teamName}/${this.streamName}`;
    this._strategyWidgetServce.streamUpdate$.next({ parentId: this.id, streamName: this.streamName });
    this.load();
    if (this.modelName) {
      this.modelSelect(this.modelName)
    }
    this._strategyWidgetServce.intervalUpdate$.next({ parentId: this.id, interval: interval });
  }

  public initTraffic() {
    if (this._intervalId) clearInterval(this._intervalId);
    if (this._socketSub) {
      this._socketSub.unsubscribe();
      this._socketSub = null;
    }

    if (this._settingsService.chartTraffic === 'SOCKET') {
      this.loadData(); // don't wait
      this._socketSub = this._socketService.subscribeToRoomMessages(this.streamName).subscribe((response) => {
        if (!response) return;

        if(response.values) {
          const values = { ...response.values };
          this.chartData.push(values);
          this._strategyWidgetServce.parentChartDataUpdate$.next({ parentId: this.id, chartData: this.chartData });

          this._ohlcDataSeries.append(values.t, values.o, values.h, values.l, values.c);
          this._volumeSeries.append(values.t, values.v);
          if (this.sciChartSurface.zoomState !== EZoomState.UserZooming) {
            this.mainChartXAxes.visibleRange = new NumberRange(this.chartData[0].t, values.t + 1000);
          }
        }
        else {
          this.loadData();
        }

      });
    } else {
      this._intervalId = window.setInterval(() => {
        this.loadData();
      }, this.updateFrequency * 1000);
    }
  }
  public init() {
    this.updateTickerAutocomplete(this.ticker);
    this.modifiers.cursor.tooltipLegendTemplate = this.getTooltipLegendTemplate.bind(this);
    setTimeout(async () => {
      this.verticalGroup = new SciChartVerticalGroup();
      await this.initMainChart();
      this._subs.add = this._settingsService.chartTraffic$.subscribe((response: any) => {
        this.initTraffic();
      });

      this._subs.add = this.mainChartXAxes.visibleRangeChanged.subscribe((data1) => {
        if (this.xValues && this.xValues.length > 0 && data1.visibleRange.min < this.xValues[0]) {
          // get more data
          this.loadChartData(Math.trunc(data1.visibleRange.min), Math.trunc(data1.visibleRange.max));
        }
        this._strategyWidgetServce.parentXAxisUpdate$.next({ id: this.id, visibleRange: data1.visibleRange });
      });

      this._subs.add = this._strategyWidgetServce.childXAxisUpdate$.subscribe((response) => {
        const hasChild = this.children.find((x) => x === response.id);
        if (!hasChild) return;

        this.mainChartXAxes.visibleRange = response.visibleRange;
      });

      this._settingsService.chartTheme$.subscribe(this.$changeTheme.bind(this));
      this._settingsService.theme$.subscribe(this.$changeTheme.bind(this));
    }, 100);
  }

  public async initMainChart() {
    const theme = this._settingsService.getChartThemeProvider();
    const { sciChartSurface, wasmContext } = await SciChartSurface.create(this.id, {
      theme,
      title: this.title,
      disableAspect: true
    });
    Object.assign(this, { sciChartSurface, wasmContext });

    this.mainChartXAxes = new DateTimeNumericAxis(wasmContext, {
      // autoRange.never as we're setting visibleRange explicitly below. If you dont do this, leave this flag default
      autoRange: EAutoRange.Never,
  });
    this.mainChartXAxes.labelProvider.formatCursorLabel = (dataValue) => {
      return new Date(dataValue * 1000).toLocaleString();
    };

    sciChartSurface.xAxes.add(this.mainChartXAxes);

    this.mainChartYAxes = new NumericAxis(wasmContext, { labelPrefix: '$', labelPrecision: 2, growBy: new NumberRange(0.1, 0.1) });
    sciChartSurface.yAxes.add(this.mainChartYAxes);

    sciChartSurface.yAxes.add(
      new NumericAxis(wasmContext, {
        id: Y_AXIS_VOLUME_ID,
        growBy: new NumberRange(0, 4),
        isVisible: false,
        autoRange: EAutoRange.Always
      })
    );

    sciChartSurface.chartModifiers.add(...Object.values(this.modifiers));

    const dataPointSelectionModifier = this.modifiers.dataPoint;
    dataPointSelectionModifier.allowDragSelect = false;
    (dataPointSelectionModifier.getSelectionMode = (modifierKeys, isAreaSelection) => {
      this.modifierKeys = modifierKeys;
      return ESelectionMode.Replace;
    }),
      dataPointSelectionModifier.selectionChanged.subscribe((args) => {
        if (args.selectedDataPoints.length > 1) {
          console.warn(`Multiple Data Points Selected: ${args.selectedDataPoints.length}`);
          return;
        }

        if (args.selectedDataPoints.length == 1) {
          const datapoint = args.selectedDataPoints[0];
          const date = new Date(this.xValues[datapoint.index] * 1000);
          this.clickMinuteBar(date);
        }

        this.verticalGroup.addSurfaceToGroup(sciChartSurface);
      });
  }

  public async clickMinuteBar(date) {
    if (this.showMinuteBar && this.lastMinuteBarDate === date.toISOString()) {
      this.showMinuteBar = false;
      return;
    }
    if (!this.showMinuteBar && !this.modifierKeys.shiftKey) {
      return;
    }

    if(this.interval != "1minute") {
      this.openSnackBar("Switch to `1minute` interval to drill down into minute-bars.", 'OK');
      return;
    }

    this.onTickSelect.emit({ parentId: this.id, streamName: this.streamName, ticker: this.ticker, date });
  }

  async updateTickerAutocomplete(value) {
    const symbols: any[] = <any>await this._streamsService.symbolSearch(this.dataFeedUrl, value, 20);
    if (symbols && symbols.length > 0) {
      this.tickers = symbols;
      if(symbols.length == 1)
        this.ticker = this.tickers[0].symbol;
    }
  }

  public getTooltipLegendTemplate = (seriesInfos: SeriesInfo[], svgAnnotation: CursorTooltipSvgAnnotation) => {
    let outputSvgString = '<svg></svg>';
    let circle = '';

    if (this.isLoading) {
      return outputSvgString;
    }

    seriesInfos.forEach((seriesInfo, index) => {
      const y = 20 + index * 20;
      let textColor = '#7393B3';

      let legendText = '';
      if (seriesInfo.dataSeriesType === EDataSeriesType.Ohlc) {
        const o = seriesInfo as OhlcSeriesInfo;
        legendText = `Open=${o.formattedOpenValue} High=${o.formattedHighValue} Low=${o.formattedLowValue} Close=${o.formattedCloseValue}`;
      }

      if (seriesInfo.dataSeriesType === EDataSeriesType.Xy) {
        if (seriesInfo.seriesName === 'Volume') {
          legendText = `Volume=${seriesInfo.formattedYValue}`;
        } else {
          legendText = `&nbsp;&nbsp;&nbsp;&nbsp;${seriesInfo.seriesName}=${seriesInfo.formattedYValue}`;
          circle = `<circle cx="5" cy="${y - 5}" r="2" stroke="${seriesInfo.stroke}" stroke-width="4" fill="${seriesInfo.stroke}" />`;
        }
      }

      outputSvgString += `${circle} <text x="3" y="${y}" font-size="14" font-family="Roboto, 'Helvetica Neue', sans-serif" fill="${textColor}">
            ${legendText}
        </text>`;
    });

    return `<svg width="100%" height="100%">
                ${outputSvgString}
            </svg>`;
  };

  private setToMidnight(timestamp) {
    return this.setTime(timestamp, 0,0,0,0);
  }

  private setTime(timestamp, hours, minutes, seconds, milliseconds) {
    let date = new Date(timestamp * 1000);
    date.setHours(hours, minutes, seconds, milliseconds);
    return Math.floor(date.getTime() / 1000);
  }

  lastFrom = null;
  lastTo = null;
  startTimestamp = "";
  endTimestamp = "";
  startIsLoading = false;
  endIsLoading = false;
  calls = 0;
  returns = 0;
  last_strats = null;
  lastChartData = null;
  old_ticker = null;
  old_interval = null;
  annotations = [];
  @debounce(500)
  public loadChartData(from, to) {
    if (this.ticker !== this.old_ticker || this.interval != this.old_interval) {
      this.reset();
    } else {
      if (this.lastChartData && this.lastChartData.from == from && this.lastChartData.to == to) {
        return;
      }
    }
    this.lastChartData = { from: from, to: to };

    if (this.chartData.length > 0) {
      const first = this.chartData[0].t;
      const last = this.chartData[this.chartData.length - 1].t;

      if (from >= first && to <= last) {
        return;
      }
    }

    let countback = 3000;

    let dataFeedUrl = this.dataFeedUrl;

    this.calls++;
    debug("REQUEST", formatDate(from), formatDate(to))
    if(!this.lastFrom || from < this.lastFrom) {
      this.startIsLoading = true;
    }
    this.lastFrom = from;
    if(!this.lastTo || to > this.lastTo) {
      this.endIsLoading = true;
    }

    this.lastTo = to;

    var sub1 = this._streamsService.getMarketData(dataFeedUrl, this.ticker, this.interval, from, to, countback, this.strats).subscribe((response) => {
      this.returns++;
      sub1.unsubscribe();
      let newData = [];
      if ((<any>response).s == 'no_data') {
        const nextTime = (<any>response).nextTime;
        if(nextTime) {
          debug("RECEIVE no data", formatDate(nextTime))
          // do something intelligent with nextTime
          setTimeout(() => {
            let to = new Date(parseInt(nextTime)).getTime();
            if (to) {

              let from = to - this.graphIncrement;

              //add padding to prevent scichart rendering bug
              if (this.interval === '1day') {
                from = this.setToMidnight(from);
                from = from - 86400; // subtract 1 day
                to = this.setToMidnight(to);
              }

              this.loadChartData(from, to);
            }
          }, 100);
        }
      } else {
        const timestamps = (<any>response).t;
        const start = timestamps[0];
        const end = timestamps[timestamps.length - 1];
        debug("RECEIVE", formatDate(start), formatDate(end));

        for (var i = 0; i < (<any>response).t.length; i++) {
          let thisObj: any = {};
          Object.keys(response)
            .filter((x) => x != 's')
            .forEach((key) => (thisObj[key] = (<any>response)[key][i]));

          const foundIndex = this.chartData.findIndex((x) => x.t == thisObj.t);
          if (foundIndex != -1) {
            if (JSON.stringify(this.chartData[foundIndex]) !== JSON.stringify(thisObj)) {
              Object.keys(response).forEach((key) => (this.chartData[foundIndex][key] = thisObj[key]));
            }
          } else {
            newData.push(thisObj);
          }
        }
      }

      if(this.calls == this.returns) {
        this.startIsLoading = false;
        this.endIsLoading = false;
      }

      if (newData.length == 0) {
        return;
      }

      const combined = this.chartData.concat(...newData);
      combined.sort((a, b) => a.t - b.t);
      const distinct = combined.filter((value, index, array) => {
        return array.find((x) => x.t == value.t) == value;
      });

      let cloned = Object.keys(distinct[0]).reduce((acc, key) => {
        acc[key] = null;
        return acc;
      }, {});

      let intervalSeconds = 60;

      switch(this.interval) {
        case "1minute":
          break;
        case "5minute":
          intervalSeconds = 300;
          break;
        case "30minute":
          intervalSeconds = 1800;
          break;
        case "1hour":
          intervalSeconds = 3600;
          break;
        case "1day":
          intervalSeconds = 86400;
          break;
      }

      for (let i = 1; i < distinct.length; i++) {
        const gap = distinct[i]['t'] - distinct[i - 1]['t'];

        if (gap > intervalSeconds) {
          console.debug(`Gap greater than ${this.interval} found between index ${i - 1} and ${i}: ${gap} seconds`);
          let current = distinct[i - 1];

          while (current + intervalSeconds < distinct[i]['t']) {
            current['t'] += intervalSeconds;
            cloned['t'] = current['t'];
            distinct.splice(i, 0, cloned);
            i++;
          }
        }
      }

      if(this.interval == "1day") {
        distinct.forEach(x => {
          x.t = this.setTime(x.t, 12,0,0,0);
        })
      }

      this.chartData = distinct;
      this.startTimestamp = formatDate(this.chartData[0].t)
      this.endTimestamp = formatDate(this.chartData[this.chartData.length - 1].t);
      this._strategyWidgetServce.parentChartDataUpdate$.next({ parentId: this.id, chartData: this.chartData });

      this.xValues = this.chartData.map((x) => x.t);
      this.openValues = this.chartData.map((x) => x.o ?? 0);
      this.highValues = this.chartData.map((x) => x.h ?? 0);
      this.lowValues = this.chartData.map((x) => x.l ?? 0);
      this.closeValues = this.chartData.map((x) => x.c ?? 0);
      this.volumeValues = this.chartData.map((x) => x.v ?? 0);

      // this.mainChartXAxes.visibleRange = new NumberRange(this.chartData[0].t, this.chartData[this.chartData.length-1].t);
      // this.mainChartYAxes.visibleRange =  new NumberRange(Math.min(...this.closeValues), Math.max(...this.closeValues));

      this.isLoading = false;
      const dataSeries = new OhlcDataSeries(this.wasmContext, {
        dataIsSortedInX: true,
        containsNaN: true,
        xValues: this.xValues,
        openValues: this.openValues,
        highValues: this.highValues,
        lowValues: this.lowValues,
        closeValues: this.closeValues
      });

      this._ohlcDataSeries = dataSeries;
      const upCol = appTheme.VividGreen;
      const downCol = appTheme.MutedRed;

      // Create and add the Candlestick series
      const candlestickSeries = new FastCandlestickRenderableSeries(this.wasmContext, {
        strokeThickness: 1,
        dataSeries,
        stroke: appTheme.ForegroundColor, // Used for legend template
        brushUp: upCol + '77',
        brushDown: downCol + '77',
        strokeUp: upCol,
        strokeDown: downCol
      });

      this.sciChartSurface.renderableSeries.clear();
      this.sciChartSurface.renderableSeries.add(candlestickSeries);
      this.chart = this.sciChartSurface;

      // Add volume data onto the chart
      this._volumeSeries = new XyDataSeries(this.wasmContext, {
        containsNaN: true,
        dataIsSortedInX: true,
        xValues: this.xValues,
        yValues: this.volumeValues,
        dataSeriesName: 'Volume'
      });

      this.sciChartSurface.renderableSeries.add(
        new FastColumnRenderableSeries(this.wasmContext, {
          dataSeries: this._volumeSeries,
          strokeThickness: 0,
          yAxisId: Y_AXIS_VOLUME_ID, // This is how we get volume to scale - on a hidden YAxis
          paletteProvider: new VolumePaletteProvider(dataSeries, upCol + '77', downCol + '77') // This is how we colour volume bars red or green
        })
      );

      this.annotations = new Array(this.chartData.length).fill(null);
      this.strats.forEach((x) => {
        for (let i = 0; i < this.chartData.length; i++) {
          if (this.chartData[i][x]) {
            this.annotations[i] = this.chartData[i][x];
          }
        }
      });

      this.addAnnotations();

      setTimeout(() => {
        if (this.activeIndicators.length > 0) this.activeIndicators.forEach((x) => this.addIndicator(x));
      }, 0);

      if (this.old_ticker !== this.ticker) {
        this.sciChartSurface.zoomExtents();
        this.old_ticker = this.ticker;
      }
      if(this.old_interval !== this.interval) {
        this.sciChartSurface.zoomExtents();
        this.old_interval = this.interval;
      }

      if (this.sciChartSurface.zoomState !== EZoomState.UserZooming) {
        this.mainChartXAxes.visibleRange = new NumberRange(this.chartData[0].t, this.chartData[this.chartData.length - 1].t + 30);
      }

    });
  }

  public addIndicator(indicator) {
    if (this.chartData.length === 0) return;
    const keys = Object.keys(this.chartData[0]);

    if (!keys.includes(indicator.name)) return;
    if (!this.activeIndicators.find((x) => x.name === indicator.name)) this.activeIndicators.push(indicator);

    const exists = this.sciChartSurface.renderableSeries.getById(indicator.name);

    if (exists) return;

    const LineData = new XyDataSeries(this.wasmContext, {
      dataSeriesName: indicator.name,
      dataIsSortedInX: true,
      containsNaN: true,
      xValues: this.xValues,
      yValues: this.chartData.map((x) => x[indicator.name] ?? NaN)
    });

    let color = null;
    const regex = /\[line:(.*?)\]/;
    let match = null;
    const existingIndicator = this.getIndicator(indicator.name);
    if (existingIndicator) {
      match = regex.exec(existingIndicator.type);
    } else {
      match = regex.exec(indicator.type);
    }

    color = match ? match[1] : '#d9d90f';

    const lineSeries = new FastLineRenderableSeries(this.wasmContext, {
      id: indicator.name,
      strokeThickness: 1,
      drawNaNAs: ELineDrawMode.DiscontinuousLine,
      dataSeries: LineData,
      stroke: color
    });

    this.sciChartSurface.renderableSeries.add(lineSeries);
  }

  public removeIndicator(id: string) {
    const exists = this.sciChartSurface.renderableSeries.getById(id);
    this.sciChartSurface.renderableSeries.remove(exists);
    this.activeIndicators = this.activeIndicators.filter((x) => x.name !== id);
  }

  public addAnnotations() {
    const buyMarkerAnnotation = (x1: number, y1: number, color: string): CustomAnnotation => {
      if (!color) {
        color = appTheme.VividGreen;
      }
      return new CustomAnnotation({
        x1,
        y1,
        verticalAnchorPoint: EVerticalAnchorPoint.Top,
        horizontalAnchorPoint: EHorizontalAnchorPoint.Center,
        svgString: `<svg id="Capa_1" xmlns="http://www.w3.org/2000/svg">
                  <g transform="translate(-54.867218,-75.091687)">
                      <path style="fill:${color};fill-opacity:0.77;stroke:${color};stroke-width:2px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
                          d="m 55.47431,83.481251 c 7.158904,-7.408333 7.158904,-7.408333 7.158904,-7.408333 l 7.158906,7.408333 H 66.212668 V 94.593756 H 59.053761 V 83.481251 Z"
                      "/>
                  </g>
              </svg>`
      });
    };

    // Returns a CustomAnnotation that represents a sell marker arrow
    // The CustomAnnotation supports SVG as content. Using Inkscape or similar you can create SVG content for annotations
    const sellMarkerAnnotation = (x1: number, y1: number, color: string): CustomAnnotation => {
      if (!color) {
        color = appTheme.VividRed;
      }
      return new CustomAnnotation({
        x1,
        y1,
        verticalAnchorPoint: EVerticalAnchorPoint.Bottom,
        horizontalAnchorPoint: EHorizontalAnchorPoint.Center,
        svgString: `<svg id="Capa_1" xmlns="http://www.w3.org/2000/svg">
                    <g transform="translate(-54.616083,-75.548914)">
                        <path style="fill:${color};fill-opacity:0.77;stroke:${color};stroke-width:2px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
                        d="m 55.47431,87.025547 c 7.158904,7.408333 7.158904,7.408333 7.158904,7.408333 L 69.79212,87.025547 H 66.212668 V 75.913042 h -7.158907 v 11.112505 z"
                        />
                    </g>
                </svg>`
      });
    };
    this.sciChartSurface.annotations.clear();

    // annotations from children
    Object.keys(this.annotationMap).forEach((key) => {
      this.annotationMap[key].forEach((map) => {
        const [annotation, color, alert, name] = map.annotation.split(':');

        var closest = this.chartData.reduce((a, b) => (Math.abs(a.t - map.timestamp) < Math.abs(b.t - map.timestamp) ? a : b));
        const foundIndex = this.chartData.findIndex((x) => x.t == closest.t);
        if (foundIndex) {
          if (annotation == 'up') {
            this.sciChartSurface.annotations.add(buyMarkerAnnotation(map.timestamp, this.lowValues[foundIndex], color));
          }
          if (annotation == 'down') {
            this.sciChartSurface.annotations.add(sellMarkerAnnotation(map.timestamp, this.highValues[foundIndex], color));
          }
        }
      });
    });
    // our annotations
    for (let i = 0; i < this.annotations.length; i++) {
      if (this.xValues.length > i) {
        if (!this.annotations[i]) {
          continue;
        }
        const [annotation, color] = this.annotations[i].split(':');
        if (annotation == 'up') {
          this.sciChartSurface.annotations.add(buyMarkerAnnotation(this.xValues[i], this.lowValues[i], color));
        }
        if (annotation == 'down') {
          this.sciChartSurface.annotations.add(sellMarkerAnnotation(this.xValues[i], this.highValues[i], color));
        }
      }
    }
  }

  public modelSelect(modelName) {
    this.onModelSelect.emit({
      modelName,
      streamName: this.streamName,
      parentId: this.id,
      ticker: this.ticker,
      interval: this.interval,
      parentChartData: this.chartData
    });
  }

  public indicatorSelect($event: MatCheckboxChange, indicator) {
    if ($event.checked) {
      this.addIndicator(indicator);
    } else {
      this.removeIndicator(indicator.name);
    }

    this.onIndicatorSelect.emit({ parentId: this.id, activeIndicators: this.activeIndicators });
  }

  public close() {
    let closeChildren = false;
    if (this.children.length > 0) {
      closeChildren = confirm(
        `There are ${this.children.length} windows associated with this stream. Do you wish to close all associated windows?`
      );
    }

    if (closeChildren) this.onCloseChildren.emit(this.children);
    this.onClose.emit({ id: this.id });
  }

  public $changeTheme(): void {
    if (this.sciChartSurface) this.sciChartSurface.applyTheme(this._settingsService.getChartThemeProvider());
  }

  indicatorColorChange(color: string) {
    if (!this.selectedIndicatorName) return;

    const indicator = this.indicators.find((x) => x.name === this.selectedIndicatorName);
    if (!indicator) return;

    indicator.type = `[line:${color}]`;
    this.saveIndicator(indicator);

    const index = this.activeIndicators.findIndex((x) => x.name === this.selectedIndicatorName);
    if (index < 0) return;

    this.activeIndicators[index] = indicator;

    const exists = this.sciChartSurface.renderableSeries.getById(this.selectedIndicatorName) as FastLineRenderableSeries;
    if (!exists) return;

    exists.stroke = color;
  }

  indicatorClick($event: any, indicator: any) {
    $event.stopPropagation();

    if (this.selectedIndicatorName && this.selectedIndicatorName === indicator.name) {
      this.selectedIndicatorName = null;
      return;
    }

    this.selectedIndicatorName = indicator.name;
  }

  saveIndicator(indicator: any) {
    localStorage.setItem(`${this.id}:${indicator.name}`, JSON.stringify(indicator));
  }

  getIndicator(indicatorName: string) {
    const key = `${this.id}:${indicatorName}`;

    const item = localStorage.getItem(key);
    if (!item) return null;

    return JSON.parse(item);
  }

  getIndicatorStyle(indicator) {
    let saved = this.getIndicator(indicator.name);

    if (!saved) saved = indicator;

    const regex = /\[line:(.*?)\]/;
    const match = regex.exec(saved.type);
    const color = match ? match[1] : '#d9d90f';

    return {
      cursor: 'pointer',
      'box-sizing': 'border-box',
      height: '15px',
      width: '15px',
      'border-style': 'solid',
      'border-color': '#aaa',
      'border-width': '1px',
      'background-color': color
    };
  }

  isActive(item) {
    if (this.activeIndicators.find((x) => x.name === item.name)) return true;

    return false;
  }


  public openSnackBar(message: string, action: string): void {
    const durationInSeconds = 10;
    const horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    const verticalPosition: MatSnackBarVerticalPosition = 'top';

    this._snackbar.open(message, action, {
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
      duration: durationInSeconds * 1000
    });
  }
}
